import * as React from 'react';
import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  Button,
  TextField,
  Grid
 } from '@mui/material';
 import { styled } from '@mui/material/styles';
 import './landing.css'


const RED_COLOR = '#E6323B';
const useStyles = makeStyles((theme) =>({
  heroContainer: {

  },
  heroLeft: {
    padding: '50px',
  },
  heroTitle: {
  },
  heroActions: {
  },
  heroTextField: {
  },
  heroCTA: {
  },
  heroRight: {
    padding: '50px',
  },
}));

const HeroContainer = styled('div')({
  // height: '100vh',
});

const HeroTitle = styled('h1')(({theme}) => ({
    fontSize: '12vw',
    fontWeight: 900,
    color: RED_COLOR,
    [theme.breakpoints.up('sm')]: {
      fontSize: '3em',
    }

}))

const HeroDescription = styled('div')({
  fontWeight: 500,
  marginBottom: '20px',
  fontSize: '1.25em',
  lineHeight: '1.5em'
})

const HeroAction = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    minWidth: '50%'

})

const HeroCTA = styled(Button)({
    width: '100%',
    background: RED_COLOR,
    height: '56px',
    borderRadius: '30px',
    '&:hover': {
      backgroundColor: '#e6323bbf'
    },
});

const Feature = styled('div')({
  display: 'flex',
  justifyContent:'center',
  alignItems:'center',
  flexDirection: 'column',
  textAlign: 'center',
  padding: '20px',
  '& h1': {
    fontSize: '5em',
    color: RED_COLOR,
  },
  '& h2': {
    color: RED_COLOR,
    fontWeight: 900,
  },
  '& p': {
    fontSize: '1.25em',
    fontWeight: 500
  }
})

const HowItWorksContainer = styled('div')({
  background: `url('https://i.groupme.com/414x596.png.59961566a95a481c92564941f9fd856b')`,
  backgroundSize: 'contain',
  '> h1': {
    textAlign: 'center',
    fontWeight: 900,
  },
  paddingTop: '20px',
  paddingBottom: '20px'

});



const createHowToMap = (index, label, description) => {
  return {
    index,
    label,
    description
  }
}

const HERO_CONTENT = {
  label: `Create a Thoughtful and Original Valentine's Day Game in an Instant`,
  description: `Impress your valentines with a game experience created just for them - no shipping required. Loventines are afforable, original, and guaranteed to get you some Valentine's Day bonus points!`
}

const howToMap = [
  createHowToMap(1, "Answer a few questions", "We take your answers and create a personal website and game with them 📝"),
  createHowToMap(2, "Get your QR Code", "Save the code to your phone or print it out and place it in a card 📲"),
  createHowToMap(3, "Scan and Play", "Have your valentines scan the code on your phone or print and play the game. Instant win 💕"),
]

const redText = (text) => <span style={{color:RED_COLOR}}>{text}</span>



const Landing = () => {
  const classes = useStyles();
  const [emailText, setEmailText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [showThankYou, setShowThankYou] = React.useState(false);

  const onTextFieldChange = (event) => {
    console.log(event.target.value)
    setEmailText(event.target.value)
  }

  const onSubmitEmail = () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('EMAIL', emailText);
    formData.append('b_70e9c7d4f8c46722d130e877a_bca39879db','');
    fetch('https://gmail.us14.list-manage.com/subscribe/post?u=70e9c7d4f8c46722d130e877a&amp;id=bca39879db', {
      body: formData,
      method: 'post'
    }).then(() => {
      setShowThankYou(true);
      setLoading(false);

    })
    .catch(() => {
      // The email still gets added to the mailchimp. Is there a workflow that we can do.
      setLoading(false);
      setShowThankYou(true);
      // alert("There was an error sending in your email. Try again.")
    })
  }

  const CTAElement = ({style}) => {
    let content = null;
    if (loading) {
      content = (
        <CircularProgress />
      );
    } else if (showThankYou) {
      content = (
        <div style={{padding: '10px', background: RED_COLOR, color: 'white', borderRadius: '20px', fontWeight: 'bold', textAlign: 'center'}}>
          Good Choice! Expect an email soon with instructions on what to do next.
        </div>
      );
    } 
    return (
      <HeroAction style={style}>
        <HeroDescription>
          {HERO_CONTENT.description}
          <br />
          <br />
          Enter your email below to Get started.
        </HeroDescription>
        {content}
      </HeroAction>
    )
  }

  return (
    <>
      <HeroContainer>
        <Grid container>
          <Grid item xs={12} md={6} className={classes.heroLeft}>
            <div>
                <img alt="logo" src="https://i.groupme.com/395x96.png.7a5724c946e14dee9bb7ea36505e753b" width="200px" />
            </div>
            <HeroTitle>
              {HERO_CONTENT.label}
            </HeroTitle>
            <CTAElement />
            {!loading && !showThankYou && (
              <>
                <TextField 
                  placeholder="email" 
                  type="email" 
                  className={classes.heroTextField}
                  InputProps={{style:{borderRadius:'30px'}}}
                  onChange={onTextFieldChange}
                  id="email"
                  name="email"
                  value={emailText}
                  style={{

                    borderColor: RED_COLOR,
                    width: '100%',
                    borderRadius: '30px',
                    marginBottom: '20px'
                  }}
                />
                <HeroCTA 
                  variant="contained"
                  className={classes.heroCTA}
                  onClick={onSubmitEmail}
                  >
                    Get Started
                </HeroCTA>
              </>

            )}
            <div className={classes.heroActions}>
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.heroRight}>
            <img alt="phone example" src="https://i.groupme.com/833x1091.png.2cb0e05f2cc343e8bd833eb751c8ac1b" width="100%" />
            {/* <div 
            style = {{
              position: 'absolute',
              bottom: 0,
              right: 30
            }}>
              <img alt="omg, this is so cute. i love it" src="https://i.groupme.com/224x181.png.56ed9cb704fa4df6866a3630ecac447e" />
            </div> */}
          </Grid>
        </Grid>
      </HeroContainer>
      <HowItWorksContainer>
        <h1 style={{marginBottom: '80px'}}>
          How It Works
        </h1>
        <Grid container spacing={3}>
          {howToMap.map((feature, index) => (
            <Grid item xs={12} sm={4} >
              <Feature>
                <h1 >
                  {index +1}
                </h1>
                <h2>
                  {feature.label}
                </h2>
                <p>
                  {feature.description}
                </p>
              </Feature>
            </Grid>
          ))}
        </Grid>

      </HowItWorksContainer>
      <div style={{padding: '20px'}}>
        <h1 style={{textAlign: 'center'}}>
          Let me guess, you were just going to buy {redText('flowers')} and {redText('candy')} like {redText('93%')} of people? 
          <br/>
          <br/>
          If you're basic ... just say that. 
          <br/>
          I'm sure your valentine will feel special.
          <br/>
          <br/>
          🙄
          <br/>
          <br/>
          And God forbid your present doesn't get shipped on time or the restaurant cancels your reservation due to 'capacity'
          <br/>
          <br/>
          😅
          <br/>
          <br/>
          {redText(`Don't worry, we got you`)}
          <br/>
          <br/>
        </h1>
      </div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '100px', marginBottom: '100px'}}>
        <Grid item xs={12} sm={8} style={{textAlign: 'center'}}>
            <img alt="logo" src="https://i.groupme.com/395x96.png.7a5724c946e14dee9bb7ea36505e753b" width="200px" />
            <CTAElement style={{justifyContent: 'center', alignItems: 'center', marginTop: '40px', padding: '0 10px'}} />
            {!loading && !showThankYou && (
              <>
                <TextField 
                  placeholder="email" 
                  type="email" 
                  className={classes.heroTextField}
                  InputProps={{style:{borderRadius:'30px'}}}
                  onChange={onTextFieldChange}
                  id="email"
                  name="email"
                  value={emailText}
                  style={{

                    borderColor: RED_COLOR,
                    width: '100%',
                    borderRadius: '30px',
                    marginBottom: '20px'
                  }}
                />
                <HeroCTA 
                  variant="contained"
                  className={classes.heroCTA}
                  onClick={onSubmitEmail}
                  >
                    Get Started
                </HeroCTA>
              </>

            )}
        </Grid>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        marginBottom: '20px'


      }}>
        Made with ❤️ by Tremayne Stewart
        <a href="https://instagram.com/_thesingleton"><img alt="instagram logo" src="https://cdns.iconmonstr.com/wp-content/assets/preview/2016/240/iconmonstr-instagram-11.png" width="30px" style={{marginLeft: '10px', marginRight: '10px'}} /></a>
        <a href="https://www.linkedin.com/in/tremaynestewart/"><img alt="linkedin logo" src="https://cdns.iconmonstr.com/wp-content/assets/preview/2012/240/iconmonstr-linkedin-3.png" width="30px" /></a>
      </div>
    </>
  );
}

export default Landing;